import React, { useState } from "react";
import "./PolicyPage.scss";

const PolicyPage = () => {

  return (
    <div className="policy">
      <div className="policy_content">
        <h2>Privacy Policy</h2>
        <div className="policy_content_textContent">
          <article>
            <h3>
              A message from the Clever team
            </h3>
            <p>
              We created Hello Clever ('Clever', 'we', 'us') so everyone could take control back over their data. We knew it could help so many people in Australia, Asia-Pacific and eventually, the world. This comes with a big responsibility that we <strong><ins>must</ins> </strong> do the right thing with the data we hold ourselves.<br></br>
              That is why we <strong><ins>never</ins> </strong>sell any of your personal information. And that's why our number one focus is always how we store, analyse and protect your personal data.
              <br></br>
              So how do we actually use your data?
              <br></br>
              We use the data you share with us to inform you with your financial position alongside super-smart suggestions we believe will help you spend and save clever.
              <br></br>
              Sometimes when you move to a new provider off the back of one of Clever's suggestions, they may pay us for the introduction. We always make sure you know when this happens. Crucially, this never influences Clever's view of what's right for you - and has zero impact on the kinds of things Clever suggests to you. Clever algorithms on what to suggest aren't programmed to take account of any payments we might receive.
              <br></br>
              We believe our approach to privacy is all about being transparent upfront, always being on your side because your business is our business.
              <br></br>
              <br></br>
              Kindest regards,
              <br></br>
              <strong>Team Clever.</strong>
            </p>
          </article>
          <article>
            <h3>
              1. We respect your privacy
            </h3>
            <p>
              Aircrex Pty Ltd T/A Hello Clever respects your right to privacy and is committed to safeguarding the privacy of our customers and software application users. We adhere to the Australian Privacy Principles established under the Privacy Act 1988 (Cth). This policy sets out how we collect and treat your personal information.
            </p>
            <h4>What this Privacy Policy contains</h4>
            <ol>
              <li>Personal Information</li>
              <li>What personal data do we use and how do we use it?</li>
              <li>Our legal basis for using your personal data</li>
              <li>Your key rights</li>
              <li>Where do we store your personal data?</li>
              <li>Protecting your personal data</li>
              <li>Our contact details</li>
              <li>Complaints to the Office of the Australian Information Commissioner (OAIC)</li>
            </ol>
          </article>
          <article>
            <h3>
              1. Personal Information
            </h3>
            <p>
              We collect personal information from Users directly, through their interactions with the Site and the Services, and through third parties, as described below.
              <br></br>
              We may collect personal information from Users in a variety of ways, including, but not limited to, when Users visit or use our Site, register for or use a Service, and in connection with other activities, services, features or resources we make available on or through our Site. Users may be asked for, as appropriate, name, address, email address, telephone number, date of birth or other related organisation for the limited purpose of offering the Services and banking or other account information.
              <br></br>
              Users may also provide us with other personal information when they contact us with questions, inquiries, complaints or suggestions. We may also collect information about how Users use our Site and Services.
              <br></br>
              If you create an account, you agree that we may verify your name, address, phone number, and other account details with information about you held by third party sources and your mobile service provider in order to verify your identity and help protect against potential fraud, such as when someone other than you is trying to use your account or conduct a transaction without your permission, and you provide your consent for them to disclose and/or compare your information for this purpose. Your personal information will not be shared with any third parties for any other purpose.
            </p>
          </article>
          <article>
            <h3>
              2. What personal data do we use and how do we use it?
            </h3>
            <p>
              What do we mean when we say 'personal data'?
              <br></br>
              Personal data is any data relating to a person who is identified or who can be identified from the data itself (so it would include things like a name, an identification number, or an online identifier).
              <br></br>
              You may give us personal data by interacting with the Clever app, by visiting our website, or by corresponding with us.
              <br></br>
              We (or third parties on our behalf) may collect and use any of the following categories of personal data about you:
              <br></br>
              Registration Information/Contact Details - mobile number, email, and preferred name.
              <br></br>
              Account Information - information about connected accounts (for example provider name, product type, account numbers, sort codes) as well as the account held with Clever (for example, account status, security data, preferences).
              <br></br>
              Transactional Information - information about transactions and balances from connected accounts as well as any manually entered by you.
              <br></br>
              Additional Personal Information - location (if permission given), address, postcode, device details, interaction tracking data and data customised by you (e.g. tags, updated categories), supplementary information we may ask you from time to time (for example, whether you are a student).
              <br></br>
              Technical Information - your IP address and general processing or monitoring data for performance and technical purposes
              <br></br>
              Unique Customer Identifier - a reference number which relates to you as an individual.
              <br></br>
              PayID - an email address, ABN, phone number which relates to your bank account.
              <br></br>
              We collect and use the data outlined above to provide a contracted service to you or to further operate and develop our business.
              <br></br>
              Your personal data will not be sold, distributed, or leased to any third parties. We only share your personal data in cases in which it is necessary for us to provide our services.
              <br></br>
              We do not collect information regarding your race, ethnicity, religious or philosophical beliefs, political beliefs, sexual orientation, genetic information, or information about your health.
            </p>
          </article>
          <article>
            <h3>
              3. Our legal basis for using your personal data
            </h3>
            <p>
              We consider the legal bases for using your personal data as set out in this Privacy Policy are as follows:
              <br></br>
              <strong>(A) Contractual performance:</strong> If you download our app and want us to deliver you our services, we can only perform these services if we can process your personal data for this purpose. Therefore, most of our processing of your personal data is necessary to perform the contract we have in place with you, as outlined in the 'What personal data do we use and how do we use it?' section above.
              <br></br>
              Please be aware that if you do not want us to process your personal data for most of the purposes set out above, then we cannot deliver our services to you through the Clever app. Without the use of your data there is no benefit to the Clever app and so if you don't wish your personal data to be processed in this way, then you shouldn't use the Clever app.
              <br></br>
              <strong>(B) Legal obligations:</strong> We will also process your personal data where we are under a legal obligation to do so to. For example, to:
              <br></br>
              Prevent and detect fraud, money laundering, other crime, and security issues.
              <br></br>
              Comply with laws and regulations, as well as any sector-specific mandatory guidelines and regulations.
              <br></br>
              <strong>(C) Legitimate interest:</strong> Where neither (A) nor (B) apply, we use your personal data on the basis of our legitimate interest, or the legitimate interests of others. Our legitimate interests are to:
              <br></br>
              Understand our user demographic, preferences and behaviours so we can improve the relevance of Clever to individual customers;
              <br></br>
              Track the use of the Clever app and website and identify areas where we can improve performance or functionality (business and technical);
              <br></br>
              Keep going as a business and continue to provide a free service, which means we may provide aggregated market research services, from which all personal data is removed, to other businesses in return for revenue;
              <br></br>
              To help us grow and develop our business and help our customers with the account opening process;
              <br></br>
              Operate our website and app generally;
              <br></br>
              Carry out marketing, market research and business development;
              <br></br>
              Provide services to our customers;
              <br></br>
              Invest in and roll out new services to benefit our existing customers and to attract new customers;
              <br></br>
              And for business purposes.
            </p>
          </article>
          <article>
            <h3>
              4. Your key rights
            </h3>
            <p>
              You have the following rights in relation to your personal data:
              <br></br>
              <strong>Right to object:</strong> You have a right to ask us to consider any valid objections which you have to our use of your personal data where we process your personal data on the basis of our or a third party's legitimate interest.
              <br></br>
              <strong>Right of access:</strong> All data that you have provided to us is accessible through the app. You can request a copy of all personal data you have provided us through the app or by sending us an email from the email address you use for your Clever account. We can only give you the data we hold ourselves. Any data that a partner holds about you is with that partner. You should request access to that data directly with them. We will help you where we can.
              <br></br>
              <strong>Right to data portability:</strong> You have a right to ask us to provide your personal data to a third-party provider of services.
              <br></br>
              <strong>Right to rectification:</strong> You can control your data through the app. Where you cannot change this data through the app, you have the right to ask us to rectify inaccurate or incomplete personal data which we have about you.
              <br></br>
              <strong>Right to erasure:</strong> You have the right to ask us to erase your personal data. You can close your Clever account by emailing us at team@helloclever.co, or by using the appropriate feature in the app, when available. We will then anonymise and archive all data we have about you 12 months later (we keep this data for 12 months in case of any subsequent queries and for audit requirements, unless you ask us to delete it as soon as possible following closure which you can do by sending us an email at team@helloclever.co. Be aware that the partners you have used may still have data about you. You will need to contact them directly in order for them to delete this data.
              <br></br>
              <strong>Right to restrict use of your personal data:</strong> You have a right to ask us to restrict the way that we process your personal data in certain specific circumstances. We will also pass your request onto other recipients of your personal data unless that is impossible or involves disproportionate effort.
              <br></br>
              If you wish to exercise any of the rights set out above, please email us at <strong><ins><a href="mailto:team@helloclever.co">team@helloclever.co</a></ins></strong>. We aim to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.
            </p>
          </article>
          <article>
            <h3>
              5. Where do we store your personal data?
            </h3>
            <p>
              We take the security of your personal information very seriously and have appropriate physical, technical and administrative procedures in place to help protect your personal information from unauthorised access, use or disclosure as required by law in Australia. Once we have received your User Information, we will use strict procedures and security features to try to prevent unauthorised access including:
              <br></br>
              By encrypting any Personal Information which we transfer to Basiq
              <br></br>
              Having in place an agreement with Basiq which requires it to have in place appropriate measures to safeguard the security of the Personal Information we send to them.
              <br></br>
              Full information on Basiq's security policy can be found at <ins><a href="http://docs.basiq.io/en/articles/382581-basiq-privacy-policy" target="_blank">http://docs.basiq.io/en/articles/382581-basiq-privacy-policy</a></ins>
            </p>
          </article>
          <article>
            <h3>
              6. Protecting your personal data
            </h3>
            <p>
              We encrypt personal data appropriately and use proper technical and organisational measures across the business.
              <br></br>
              All of the personal data we hold is hosted on Azure's cloud platform, which provides us with a wide range of resilience, scaling and security features. Azure's cloud platform is hosted on the Microsoft's platform.
              <br></br>
              All third-party interactions with the Clever service are made through a secure socket layer (SSL), the standard security technology for establishing an encrypted link between a web server and a browser.
              <br></br>
              We have written contracts with each of those third-party processors which contain safeguards for your information.
            </p>
          </article>
          <article>
            <h3>
              7. Our contact details
            </h3>
            <p>
              You may request access to or correction of your personal information in our custody or control, by writing to the address below, attention Compliance Officer. Your right to access or correct your personal information is subject to applicable legal restrictions. We may take reasonable steps to verify your identity before granting access or making corrections. If you have any questions about this Privacy Policy, or our personal information handling practices generally, please contact us at:
              <br></br>
              Aircrex Pty Ltd T/A Hello Clever
              <br></br>
              2/23 Foster Street, Surry Hills, NSW 2010
              <br></br>
              <strong><ins><a href="mailto:team@helloclever.co">team@helloclever.co</a></ins></strong>
            </p>
          </article>
          <article>
            <h3>
              8. Complaints to the Office of the Australian Information Commissioner (OAIC)
            </h3>
            <p>
              If you think we have breached the Privacy Act, or you wish to make a complaint about the way we have handled your personal information, you can contact us using the details set out below. Please include your name, email address and/or telephone number and clearly describe your complaint. We will acknowledge your complaint and respond to you regarding your complaint within a reasonable period of time. If you think that we have failed to resolve the complaint satisfactorily, then we encourage you to contact us to explain why, or you may raise your complaint with the Office of the Australian Information Commissioner (OAIC) using any of the following contact details or the privacy complaint form on the  <strong><ins><a href="https://oaic.gov.au/">AIC website</a></ins></strong>
              <br></br>
              Phone: 1300 363 992
              <br></br>
              Email: <strong><ins><a href="mailto:enquiries@oaic.gov.au">enquiries@oaic.gov.au</a></ins></strong>
              <br></br>
              Mail: GPO Box 5218, Sydney NSW 2001
              <br></br>
              <strong>Changes to our Privacy Policy:</strong> Clever may update or change this Privacy Policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about we handle personal information. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
              <br></br>
              Your continued use of the Site or Services following the posting of changes to this policy will be deemed your acceptance of those changes.
              <br></br>
              This document was last updated on May 2, 2022
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};
export default PolicyPage;
