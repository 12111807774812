import * as React from "react";
import Layout from "../components/layout";
import { StickyContainer } from "react-sticky";
import PolicyPage from "../containers/Policy/PolicyPage";

// markup
const Policy = () => {
  return (
    <StickyContainer>
      <Layout>
        <PolicyPage />
      </Layout>
    </StickyContainer>
  );
};

export default Policy;
